import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import {
  TextContent,
  MaxContainer,
} from '../styles/utility'

const NotFoundPage = () => (
  <Layout>
    <MaxContainer>
      <SEO title="404: Not found" noFollow={true} />
      <Section sectionTitle="404 Page Not Found">
        <TextContent>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </TextContent>
      </Section>
    </MaxContainer>
  </Layout>
)

export default NotFoundPage
